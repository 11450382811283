import {useContext, useEffect} from "react";
import {WebSocketContext} from "./WebSocketProvider";
import {useSnackbar} from "./Snackbar";
import {useSyncedRef} from "./SyncedRef";

export function BackgroundErrorWatcher() {
    const ctx = useContext(WebSocketContext);
    const handle = ctx.handle;
    const snack = useSnackbar();
    const snackRef = useSyncedRef(snack);

    useEffect(() => {
        return handle("background-error", (data: {
            error: string;
        }) => {
            snackRef.current.error(data.error);
        });
    }, [handle, snackRef]);

    return null;
}