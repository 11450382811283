import {
    createContext,
    MutableRefObject,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {EventEmitter, first} from "nate-react-api-helpers";
import {getEditable, TransitionKey} from "./TableEditCell";
import {CellCustomize, Column, Group, setNestedProp, TableContext, TableSelectionContext, TID} from "./Table";
import {useSnackbar} from "../Snackbar";
import {RangeActionProvider} from "./RangeActionProvider";
import {useSyncedRef} from "../SyncedRef";
import {UndoContext} from "./GlobalUndo";
import {DependencyFinder} from "./DependencyFinder";

type SelectionChange = {
    id: number;
    index: number;
    value: any;
    editing: boolean;
} |null

type SelectionRange = {
    fromId: number;
    fromIndex: number;
    toId: number;
    toIndex: number;
    ids: number[];
} | null;

type SelectedCursorChange = {
    element: HTMLElement;
}

export const errShowNoSnackInTable = "showNoSnackInTable";

export const EditContext = createContext({
    onSelect: new EventEmitter<SelectionChange>(),
    onSelectedCursorMoved: new EventEmitter<SelectedCursorChange>(),
    onRange: new EventEmitter<SelectionRange>(),
    locked: false,
    dataRef: {
        current: [] as Group<any>[],
    } as MutableRefObject<Group<any>[]>,
    hasPopout: false,
    cellCustomize: null as CellCustomize<any>|null,
    setHasPopout: (input: boolean) => {},
    transition: (input: TransitionKey) => {},
    update: (input: any) => {
        return Promise.resolve<any>(null);
    },
    reload: () => Promise.resolve(),
})

type Dir = -1|1;

export function EditProvider<T extends TID>(props: PropsWithChildren<{
    columns: Column<T>[];
    data: Group<T>[];
    locked?: boolean;
    onChangeHook?: (src: T | null, dst: T) => void;
    onChange?: (input: T) => Promise<any>;
    name: string;
    cellCustomize?: CellCustomize<T>
}>) {
    const onSelect = useMemo(() => new EventEmitter<SelectionChange>(), []);
    const onRange = useMemo(() => new EventEmitter<SelectionRange>(), []);
    const onSelectedCursorMoved = useMemo(() => new EventEmitter<SelectedCursorChange>(), []);

    const onChangeRef = useRef(props.onChange);
    onChangeRef.current = props.onChange;

    const onChangeHookRef = useRef(props.onChangeHook);
    onChangeHookRef.current = props.onChangeHook;

    const columnsRef = useRef(props.columns);
    columnsRef.current = props.columns;

    const hasPendingRefresh = useRef(false);

    const tbl = useContext(TableContext);
    const reloadTable = tbl.reload;

    const selectionCtx = useContext(TableSelectionContext);
    const selectionCtxUpdate = selectionCtx.update;
    const selectionCtxOnRefresh = selectionCtx.onRefresh;
    const reloadTableRef = useRef(reloadTable);
    reloadTableRef.current = reloadTable;

    useEffect(() => {
        return selectionCtxOnRefresh(props.name, () => {
            return reloadTableRef.current(true);
        });
    }, [selectionCtxOnRefresh, props.name])

    useEffect(() => {
        const sub = onSelect.subscribeAndFireLast(value => {
            selectionCtxUpdate(props.name, value?.value || null);
        });
        return () => sub.cancel();
    }, [onSelect, props.name, selectionCtxUpdate]);

    const snack = useSnackbar();
    const snackError = snack.error;
    const snackSuccess = snack.success;
    const lockedRef = useSyncedRef(props.locked || false);

    const undoCtx = useContext(UndoContext);
    const pushUndo = undoCtx.push;

    const undo = useCallback(async (last: T) => {
        try {
            if(lockedRef.current) throw new Error("Can't edit a locked table")
            if(!onChangeRef.current) throw new Error("Missing updater");

            const rs = await onChangeRef.current(last);
            reloadTable(true);
            snackSuccess("Undone");
            return rs;
        } catch(e: any) {
            snackError(e.toString());
            reloadTable(true);
            return null;
        }

    }, [reloadTable, snackError, onChangeRef, lockedRef, snackSuccess])

    const update = useCallback(async (input: T) => {

        if(!onChangeRef.current) return;
        hasPendingRefresh.current = true;

        let currentRef: T|null = null;

        if(input.id && input.id > 0) {
            dataRef.current.map(c => {
                const find = first(c.rows, r => r.id === input.id);
                if(find) currentRef = find
                return null;
            })

            if(currentRef) {
                const prev = Object.assign({}, currentRef);
                pushUndo(() => undo(prev))
            }
        }

        if(currentRef) {
            clearDependenciesForCellChange(columnsRef.current, currentRef, input);
        }

        if(onChangeHookRef.current) {
            onChangeHookRef.current(currentRef, input);
        }

        // update object in-place for list re-render
        if(currentRef) {
            Object.assign(currentRef, input);
        }

        try {
            if(lockedRef.current) throw new Error("Can't edit a locked table")
            const rs = await onChangeRef.current(input);

            if(!currentRef) {
                const prev = Object.assign({}, rs, {
                    archived: true,
                });
                pushUndo(() => undo(prev))
            }

            reloadTable(true);
            snackSuccess("Updated");
            return rs;
        } catch(e: any) {
            let showError = true;

            if(typeof e === "object" && "message" in e) {
                if(e.message === errShowNoSnackInTable) {
                    showError = false;
                }
            }

            if(showError) {
                snackError(e.toString());
            }

            reloadTable(false);
            return null;
        }
    }, [lockedRef, reloadTable, snackError, snackSuccess, pushUndo, undo, columnsRef]);

    const dataRef = useRef(props.data)
    dataRef.current = props.data;

    const autoRefresh = useCallback(() => {
        if(!hasPendingRefresh.current) return;

        hasPendingRefresh.current = false;
        reloadTable(true);
    }, [reloadTable]);

    const transition = useCallback((key: TransitionKey) => {
        const dt = dataRef.current

        const nextIndex = (basis: number = -1, direction: Dir = 1) => {
            basis += direction;

            for(let i = basis; i >= 0 && i < columnsRef.current.length; i += direction) {
                if(columnsRef.current[i].editable) {
                    return i;
                }
            }

            return -1;
        }

        if(!onSelect.lastValue) {
            if(dt.length === 0) return;
            onSelect.emit({
                id: dt[0].rows[0].id,
                index: nextIndex(),
                value: {id: dt[0].rows[0].id},
                editing: false,
            });
            return;
        }

        const selected = onSelect.lastValue;

        const downId = () => {
            for(let i = 0; i < dt.length; i++) {
                for(let j = 0; j < dt[i].rows.length; j++) {
                    if (dt[i].rows[j].id === selected.id) {
                        if(dt[i].rows.length > j+1) {
                            return dt[i].rows[j + 1]; // next row in group
                        } else if(dt.length > i + 1 && dt[i+1].rows.length > 0) {
                            return dt[i+1].rows[0]; // first row of next group
                        } else {
                            return null; // can't find the next cell (or it's a big jump)
                        }
                    }
                }
            }

            return null;
        }

        const upId = () => {
            for(let i = 0; i < dt.length; i++) {
                for(let j = 0; j < dt[i].rows.length; j++) {
                    if (dt[i].rows[j].id === selected.id) {
                        if(j-1 >= 0) {
                            return dt[i].rows[j - 1]; // next row in group
                        } else if(i > 0 && dt[i-1].rows.length > 0) {
                            const grp = dt[i-1];
                            return grp.rows[grp.rows.length-1]; // last row of prev group
                        } else {
                            return null; // can't find the next cell (or it's a big jump)
                        }
                    }
                }
            }

            return null;
        }

        let next, id, index;

        switch(key) {
            case "down":
                autoRefresh();

                next = downId()
                if(!next) {
                } else {
                    onSelect.emit({
                        id: next.id,
                        index: selected.index,
                        value: next,
                        editing: false,
                    });
                }
                break;
            case "prev":
            case "left":
                index = nextIndex(selected.index, -1);
                id = selected.id;

                if(index === -1) {
                    return;
                }

                onSelect.emit({
                    id: id,
                    index: index,
                    value: selected.value,
                    editing: false,
                });
                break;
            case "next":
            case "right":
                index = nextIndex(selected.index, 1);
                id = selected.id;

                if(index === -1) {
                    return;
                }

                onSelect.emit({
                    id: id,
                    index: index,
                    value: selected.value,
                    editing: false,
                });
                break;
            case "up":
                autoRefresh();

                next = upId()
                if(!next) {
                } else {
                    onSelect.emit({
                        id: next.id,
                        index: selected.index,
                        value: next,
                        editing: false,
                    });
                }
                break;
            case "blur":
                onSelect.emit(null);
                break;
        }

    }, [autoRefresh, onSelect]);

    const locked = props.locked || false;
    const [hasPopout, setHasPopout] = useState(false);
    const cellCustomize = props.cellCustomize;

    const ctx = useMemo(() => ({
        onSelect: onSelect,
        onRange: onRange,
        onSelectedCursorMoved: onSelectedCursorMoved,
        update: update,
        dataRef: dataRef,
        transition: transition,
        locked: locked,
        hasPopout: hasPopout,
        cellCustomize: cellCustomize || null,
        setHasPopout: setHasPopout,
        reload: reloadTable,
    }), [onSelect, update, dataRef, transition, reloadTable, onRange, locked, hasPopout, onSelectedCursorMoved, cellCustomize]);

    return (
        <EditContext.Provider value={ctx}>
            {props.children}
            {props.onChange && <RangeActionProvider data={props.data} onChange={props.onChange} columns={props.columns} />}
        </EditContext.Provider>
    );
}

function clearDependenciesForCellChange<T extends TID>(columns: Column<T>[], src: T, dst: T) {
    const dep = new DependencyFinder<T>(src, dst);

    for(const column of columns) {
        const editable = getEditable(column, src)

        if(editable?.type === "select") {
            editable.options(dep.forColumn(column))
        } else if(editable?.type === "lookup") {
            editable.options("", dep.forColumn(column))
        }
    }

    // @ts-ignore
    dep.keysToClear().map(k => {
        setNestedProp(dst, k, "");
    })
}