import { Cents } from "nate-react-api-helpers";
import { SubAPI } from "./SubAPI";
import {Paginated} from "./Users";
import {DateString} from "./Projects";
import {QuotePriceRider} from "./QuotePricing";

export class Pricing extends SubAPI {
    list(props: {
        project: number;
        offset?: number;
        previewChangeset?: number;
    }) {
        return this.fetcher.get<Paginated<PriceLine>>("/api/pricing", props);
    }

    upsert(input: PriceLine) {
        return this.fetcher.post<PriceLine>("/api/pricing/item", input)
    }

    listRiders(props: {
        project: number;
        offset?: number;
        previewChangeset?: number;
    }) {
        return this.fetcher.get<Paginated<PriceRider>>("/api/price-rider", props);
    }

    upsertRider(input: PriceRider) {
        return this.fetcher.post<PriceRider>("/api/price-rider/item", input)
    }

    getProjectMarkups(props: {
        project: number;
    }) {
        return this.fetcher.get<{
            "door-hm": number;
            "door-wd": number;
            "door-other": number;
            hardware: number;
            frame: number
            "div10-wr-accessory": number;
            "div10-wr-partition": number;
            "div10-locker": number;
            "div10-mailbox": number;
            "div10-corner-guard": number;
        }>("/api/pricing/project-markups", props);
    }

    updateProjectMarkups(props: {
        project: number;
        alternative?: number;
        "door-hm"?: number;
        "door-wd"?: number;
        "door-other"?: number;
        hardware?: number;
        frame?: number;
        "div10-wr-accessory"?: number;
        "div10-wr-partition"?: number;
        "div10-locker"?: number;
        "div10-mailbox"?: number;
        "div10-corner-guard"?: number;
        updateExisting: boolean;
    }) {
        return this.fetcher.post("/api/pricing/project-markups", props);
    }

    getCostsOfSimilarProduct(props: {
        product: number;
    }) {
        return this.fetcher.get<SimilarCost[]>("/api/pricing/cost-of-similar-product", props);
    }

    scaleSection(props: {
        project: number;
        ids: number[]
        totalPriceCents: number;
    }) {
        return this.fetcher.post("/api/pricing/section-total-price", props);
    }
}

export type SimilarCost = {
    product: number;
    productCode: string;
    name: string;
    companyName: string;
    date: DateString;
    poId: number;
    poNumber: string;
    unitPriceCents: number;
}

export type PriceRider = QuotePriceRider & {
    backup?: PriceRider;
};

export type PriceLine = {
    id: number;
    baseProjectPrice: number|null;
    openingName: string|null;
    opening: number|null;
    itemName: string | null;
    doorSeries: string | null;
    frameSeries: string | null;
    openingSeqNumber: number|null;
    project: number;
    productType: string;
    product: number;
    productName: string;
    categoryName: string;
    categorySortOrder?: number;
    productCode: string;
    finish: string;
    productDimensions: string;
    dimWidth: string;
    dimHeight: string;
    dimLength: string;
    qty: number;
    unitCostCents: Cents;
    markupPercent?: number;
    markupCents?: Cents;
    extendedPrice: Cents;
    revenueDelivered?: Cents;
    costDelivered?: Cents;
    createdAt: DateString;
    updatedAt: DateString;
    updatedBy: number;
    archived: boolean;

    manufacturer: number;
    manufacturerName: string;
    
    supplier: number;
    supplierName: string;

    linkedQuotePrice: number[]
    frame: Frame;
    door: Door;
    div10?: Div10;

    projectChangeset?: number;
    projectChangesetNote?: string;
    projectChangesetActive?: boolean;
    projectChangesetApproved?: boolean;
}

export type Div10Category =
    'corner-guard' | 'locker' | 'mailbox' | 'wr-accessory' | 'wr-partition';

export type Div10 = {
    id: number;

    category: Div10Category;
    productCode: string;

    partitionBracing: string;
    partitionCoating: string;
    description: string;
    note: string;

    lockerTiers: string;
    lockerSloped: boolean;
    lockerEndPanels: boolean;
    lockerBaseTrim: boolean;
    lockerCoating: string;

    mailboxLoading: string;
    mailboxMounting: string;
    mailboxTenantUnits: number;
    mailboxParcelUnits: number;

    cornerGuardFinish: string;
    cornerGuardType: string;
}

export type Frame = {
    id: number;
    series:        string;
    material:      string;
    gauge:         string;
    screenElev:    string;
    screenElevFile?: number;
    profile:       string;
    jambDepth:     string;
    construction:  string;
    handing:       string;
    hardwarePreps: string;
    label:         string;
    type:          string;
    width: string;
    height: string;
}

export type Door = {
    id:            string
    series:        string
    material:      string
    finish:      string
    gauge:         string
    elevation:          string
    core:          string
    seam:          string
    handing:       string
    hardwarePreps: string
    label: string
    width: string;
    height: string;
    thickness: string;
}