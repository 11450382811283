import React, {createContext, PropsWithChildren, useContext, useEffect, useMemo, useState} from "react";
import {EventEmitter, useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {useProjectId} from "./shopdrawing/ProjectName";
import {Project, ProjectStage, UnitSystem} from "../../api/Projects";
import {SnackContext} from "../../misc/Snackbar";
import {useAlternative} from "./quote/alternative/Alternative";
import {Alert, AlertTitle, Button, Card} from "@mui/material";

const defaultProject: Project = {
    id: 0,
    name: "",
    nameAlias: null,
    stage: "quote" as ProjectStage,
    unitSystem: "imperial" as UnitSystem,
    addressStreet: "",
    addressCity: "",
    addressProvince: "",
    addressPostal: "",
    client: 0,
    clientName: "",
    createdAt: "",
    updatedAt: "",
    contractor: undefined,
    archived: false,
    versionCode: "-",
    backgroundJobError: null,
    backgroundJobErrorDetail: null,
};

export const projectInfoReload = new EventEmitter<boolean>();

export const ProjectContext = createContext({
    value: Object.assign({}, defaultProject),
    LoadingOrErrorElement: null as any,
    update: (input: Partial<Project>) => {
        return 0 as any;
    },
    reload: () => {

    }
})

export const currentProject = new EventEmitter<Project|null>();
currentProject.emit(null);

export function ProjectProvider(props: PropsWithChildren<{}>) {
    const project = useProjectId();
    const alt = useAlternative();

    const rs = useAsync2((input) => {
        return api.projects.get(input.project, input.alternative)
    }, {project: project, alternative: alt}, [project, alt]);

    const result = rs.result;
    const reload = rs.reload;

    useEffect(() => {
        const sub = projectInfoReload.subscribe(reload)
        return () => sub.cancel()
    }, [reload]);

    const snack = useContext(SnackContext);

    const update = useAsyncAction(async (input) => {
        try {
            await api.projects.upsert(input)
            snack.success("Updated");
            reload();
        } catch (e: any) {
            snack.error(e.toString());
        }
    }, []);

    useEffect(() => {
        currentProject.emit(result);
    }, [result]);

    const ctx = useMemo(() => {
        if(!result)
            return {
                value: Object.assign({}, defaultProject),
                reload: reload,
                update: update.callback,
                LoadingOrErrorElement: rs.LoadingOrErrorElement,
            };

        return {
            value: result,
            reload: reload,
            update: update.callback,
            LoadingOrErrorElement: null,
        };
    }, [result, reload, rs.LoadingOrErrorElement, update.callback]);

    const [hideError, setHideError] = useState(hideErrorProjectUntil.project === project ? hideErrorProjectUntil.until > Date.now() : false);

    return <ProjectContext.Provider value={ctx}>
        {ctx.value.backgroundJobError && !hideError && <Card style={{position: "fixed", top: 40, right: 10, zIndex: 1000, maxWidth: 500}}>
            <Alert severity="error">
                <AlertTitle>{ctx.value.backgroundJobError}</AlertTitle>

                <div>
                    Products and pricing may not be as expected. Tech Support has already been notified.
                    Please close the project until the issue has been fixed.
                </div>

                <div style={{paddingTop: 8}}>
                    <Button onClick={() => {
                        setHideError(true)
                        hideErrorProjectUntil.until = Date.now() + 1000 * 60 * 5; // 5 minutes
                        hideErrorProjectUntil.project = ctx.value.id;
                    }}>Snooze</Button>
                </div>
            </Alert>
        </Card>}
        {props.children}
    </ProjectContext.Provider>
}

const hideErrorProjectUntil = {
    until: 0,
    project: 0,
}