import React, {PropsWithChildren} from "react";

export class ErrorBoundary extends React.Component<PropsWithChildren<{
    fallback: (err: Error, reset: () => void) => any;
}>, {
    error: Error | null;
    showFallback: boolean;
}> {
    constructor(props: any) {
        super(props);

        this.state = {
            error: null,
            showFallback: false,
        }
    }

    componentDidCatch(error: Error, info: any) {
        this.setState({ error, showFallback: true });
    }

    render() {
        if(this.state.showFallback && this.state.error) {
            return <>
                {this.props.fallback(this.state.error, () => {
                    this.setState({
                        error: null,
                        showFallback: false,
                    });
                })}
            </>
        }

        return <>{this.props.children}</>;
    }
}