import React, {Suspense} from 'react';
import {createTheme, CssBaseline, LinearProgress, ThemeProvider} from "@mui/material";
import { Route, Routes } from 'react-router-dom';
import { LoginRedirector } from './misc/LoginRedirector';
import {AuthProvider} from 'nate-react-api-helpers';
import {api} from "./api/API";
import {ConfirmationProvider} from "./misc/Confirm";
import {Snackbar} from "./misc/Snackbar";
import {Redirect} from "./misc/Redirect";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {PrintManagerProvider} from "./misc/pdf/PrintManager";
import {amber} from "@mui/material/colors";
import {ErrorBoundary} from "./ErrorBoundary";
import { NotFoundPage } from './pages/NotFoundPage';
import {Container} from "./misc/Container";
import {WhiteColumn} from "./pages/logistics/purchasing/WhiteColumn";
import {WebSocketProvider} from "./misc/WebSocketProvider";

const LoginPage = React.lazy(() => import('./pages/login/LoginPage').then((mod) => ({default: mod.LoginPage})));
const UsersPage = React.lazy(() => import("./pages/users/UsersPage").then(mod => ({default: mod.UsersPage})));
const ForgotPasswordPage = React.lazy(() => import("./pages/login/ForgotPasswordPage").then(mod => ({default: mod.ForgotPasswordPage})));
const ResetPasswordPage = React.lazy(() => import("./pages/login/ResetPasswordPage").then(mod => ({default: mod.ResetPasswordPage})));
const ProjectList = React.lazy(() => import("./pages/project/ProjectList").then(mod => ({default: mod.ProjectList})));
const Project = React.lazy(() => import("./pages/project/Project").then(mod => ({default: mod.Project})));
const ShopDrawing = React.lazy(() => import("./pages/project/shopdrawing/ShopDrawing").then(mod => ({default: mod.ShopDrawing})));
const Warehouse = React.lazy(() => import("./pages/logistics/warehouse/Warehouse").then(mod => ({default: mod.Warehouse})));
const Deliveries = React.lazy(() => import("./pages/logistics/deliveries/Deliveries").then(mod => ({default: mod.Deliveries})));
const LogisticsDashboard = React.lazy(() => import("./pages/logistics/dashboard/LogisticsDashboard").then(mod => ({default: mod.LogisticsDashboard})));
const RouteList = React.lazy(() => import('./pages/logistics/driver/RouteList').then(mod => ({default: mod.RouteList})));
const RouteStop = React.lazy(() => import('./pages/logistics/driver/RouteStop').then(mod => ({default: mod.RouteStop})));
const RouteDetail = React.lazy(() => import('./pages/logistics/driver/RouteDetail').then(mod => ({default: mod.RouteDetail})));
const Receiving = React.lazy(() => import("./pages/logistics/receiving/Receiving").then(mod => ({default: mod.Receiving})));
const Damaged = React.lazy(() => import('./pages/logistics/damaged/Damaged').then(mod => ({default: mod.Damaged})));
const Delivery = React.lazy(() => import('./pages/logistics/deliveries/Delivery').then(mod => ({default: mod.Delivery})));
const PurchaseOrderList = React.lazy(() => import("./pages/logistics/purchasing/PurchaseOrderList").then(mod => ({default: mod.PurchaseOrderList})));
const PurchaseOrder = React.lazy(() => import("./pages/logistics/purchasing/PurchaseOrder").then(mod => ({default: mod.PurchaseOrder})));
const ToSourcePopout = React.lazy(() => import("./pages/logistics/purchasing/PartsToSource").then(mod => ({default: mod.ToSourcePopout})));
const PopoutContainer = React.lazy(() => import('./misc/Container').then(mod => ({default: mod.PopoutContainer})));
const ToDeliverPopout = React.lazy(() => import('./pages/logistics/deliveries/ToDeliverPopout').then(mod => ({default: mod.ToDeliverPopout})));
const ToMovePopout = React.lazy(() => import('./pages/logistics/routes/ToMovePopout').then(mod => ({default: mod.ToMovePopout})));
const PayableList = React.lazy(() => import("./pages/finance/payables/PayableList").then(mod => ({default: mod.PayableList})));
const Payable = React.lazy(() => import('./pages/finance/payables/Payable').then(mod => ({default: mod.Payable})));
const Quote = React.lazy(() => import("./pages/project/quote/Quote").then(mod => ({default: mod.Quote})));
const RouteSuccess = React.lazy(() => import("./pages/logistics/driver/RouteSuccess").then(mod => ({default: mod.RouteSuccess})));
const Extractor = React.lazy(() => import("./pages/extractor/Extractor").then(mod => ({default: mod.Extractor})));
const RedirectToAlternative = React.lazy(() => import("./pages/project/quote/alternative/RedirectToAlternative").then(mod => ({default: mod.RedirectToAlternative})));
const TestingBanner = React.lazy(() => import("./misc/TestingBanner").then(mod => ({default: mod.TestingBanner})));
const ReviewPricing = React.lazy(() => import("./pages/project/shopdrawing/changeset/ReviewPricing").then(mod => ({default: mod.ReviewPricing})));
const PrintSubmissions = React.lazy(() => import("./pages/project/shopdrawing/changeset/PrintSubmissions").then(mod => ({default: mod.PrintSubmissions})));
const ReceiveAcceptance = React.lazy(() => import("./pages/project/shopdrawing/changeset/ReceiveAcceptance").then(mod => ({default: mod.ReceiveAcceptance})));
const SaveTrackingSession = React.lazy(() => import("./pages/project/shopdrawing/changeset/SaveTrackingSession").then(mod => ({default: mod.SaveTrackingSession})));
const ShopDrawingChangePreview = React.lazy(() => import("./pages/project/shopdrawing/ShopDrawingChangePreview").then(mod => ({default: mod.ShopDrawingChangePreview})));
const ReviewProducts = React.lazy(() => import("./pages/project/shopdrawing/releasedchange/ReviewProducts").then(mod => ({default: mod.ReviewProducts})));
const SummarizeChanges = React.lazy(() => import("./pages/project/shopdrawing/changeset/SummarizeChanges").then(mod => ({default: mod.SummarizeChanges})));
const StartReviewReleasedChanges = React.lazy(() => import("./pages/project/shopdrawing/releasedchange/StartReviewReleasedChanges").then(mod => ({default: mod.StartReviewReleasedChanges})));
const RunMfgProcess = React.lazy(() => import("./pages/manufacturing/RunMfgProcess").then(mod => ({default: mod.RunMfgProcess})));
const Waste = React.lazy(() => import("./pages/logistics/waste/Waste").then(mod => ({default: mod.Waste})));
const StockCount = React.lazy(() => import("./pages/logistics/stockCount/StockCount").then(mod => ({default: mod.StockCount})));
const StockCountReview = React.lazy(() => import("./pages/logistics/stockCount/StockCountReview").then(mod => ({default: mod.StockCountReview})));
const StockCounts = React.lazy(() => import("./pages/logistics/stockCount/StockCounts").then(mod => ({default: mod.StockCounts})));
const RouteStopGroup = React.lazy(() => import("./pages/logistics/driver/RouteStopGroup").then(mod => ({default: mod.RouteStopGroup})));
const Receive = React.lazy(() => import("./pages/logistics/receiving/Receive").then(mod => ({default: mod.Receive})));
const ManufactureDoors = React.lazy(() => import("./pages/manufacturing/doors/ManufactureDoors").then(mod => ({default: mod.ManufactureDoors})));
const ManufactureDoorPo = React.lazy(() => import("./pages/manufacturing/doors/ManufactureDoorPo").then(mod => ({default: mod.ManufactureDoorPo})));
const StockOrder = React.lazy(() => import("./pages/logistics/stockOrder/StockOrder").then(mod => ({default: mod.StockOrder})));
const StockOrderReview = React.lazy(() => import("./pages/logistics/stockOrder/StockOrderReview").then(mod => ({default: mod.StockOrderReview})));
const StockOrders = React.lazy(() => import("./pages/logistics/stockOrder/StockOrders").then(mod => ({default: mod.StockOrders})));
const WeekRoutePlanner = React.lazy(() => import("./pages/logistics/routes/weekPlanner/WeekRoutePlanner").then(mod => ({default: mod.WeekRoutePlanner})));
const ProductSettings = React.lazy(() => import("./pages/settings/products/ProductSettings").then(mod => ({default: mod.ProductSettings})));
const Manufacturers = React.lazy(() => import("./pages/settings/companies/Manufacturers").then(mod => ({default: mod.Manufacturers})));
const Client = React.lazy(() => import("./pages/settings/companies/clients/Client").then(mod => ({default: mod.Client})));
const ClientList = React.lazy(() => import("./pages/settings/companies/clients/ClientList").then(mod => ({default: mod.ClientList})));
const DesktopRouteList = React.lazy(() => import("./pages/logistics/routes/RouteList").then(mod => ({default: mod.RouteList})));
const DesktopRouteDetail = React.lazy(() => import("./pages/logistics/routes/RouteDetail").then(mod => ({default: mod.RouteDetail})));

const authIgnore = [
    "/forgot-password",
    "/reset-password",
    "/login"
]

const theme = createTheme({
    palette: {
        secondary: amber,
    },
});

function LoadingEl() {
    return (
        <Container>
            <WhiteColumn>
                <LinearProgress />
            </WhiteColumn>
        </Container>
    )
}

function App() {
  return (
      <ErrorBoundary fallback={(err: Error, reset: () => void) => {
          return <div style={{padding: 20}}>
              <h2>Oops, there was an error!</h2>
              <pre>{err.toString()}</pre>
              <textarea style={{height: 200, width: 500}}>{err.stack}</textarea>
              <div style={{paddingTop: 20}}>
                <button onClick={() => reset()}>Reset</button>
              </div>
          </div>
      }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <CssBaseline />
              <ThemeProvider theme={theme}>
                  <AuthProvider onPing={() => api.users.ping()}>
                      <WebSocketProvider>
                    <TestingBanner />
                    <ConfirmationProvider>
                        <Snackbar timeout={10 * 1000}>
                                <PrintManagerProvider>
                                <LoginRedirector ignore={authIgnore} />
                                <Routes>
                                    <Route index element={<Redirect to="/projects" />} />
                                    <Route path="/users" element={<Suspense fallback={<LoadingEl />}><UsersPage /></Suspense>} />
                                    <Route path="/projects" element={<Suspense fallback={<LoadingEl />}><ProjectList /></Suspense>} />
                                    <Route path="/project/*">
                                        <Route path=":project" element={<Suspense fallback={<LoadingEl />}><Project /></Suspense>} />
                                        <Route path=":project/shop-drawing" element={<Suspense fallback={<LoadingEl />}><ShopDrawing /></Suspense>} />
                                        <Route path=":project/shop-drawing/changeset/:changesetId/">
                                            <Route path="review-pricing" element={<Suspense fallback={<LoadingEl />}><ReviewPricing /></Suspense>} />
                                            <Route path="summarize-changes" element={<Suspense fallback={<LoadingEl />}><SummarizeChanges /></Suspense>} />
                                            <Route path="save-session" element={<Suspense fallback={<LoadingEl />}><SaveTrackingSession /></Suspense>} />
                                            <Route path="print-submissions" element={<Suspense fallback={<LoadingEl />}><PrintSubmissions /></Suspense>} />
                                            <Route path="receive-acceptance" element={<Suspense fallback={<LoadingEl />}><ReceiveAcceptance /></Suspense>} />
                                            <Route path="preview" element={<Suspense fallback={<LoadingEl />}><ShopDrawingChangePreview /></Suspense>} />
                                            <Route path="preview/:workspace" element={<Suspense fallback={<LoadingEl />}><ShopDrawingChangePreview /></Suspense>} />
                                            <Route path="preview/:workspace/:subspace" element={<Suspense fallback={<LoadingEl />}><ShopDrawingChangePreview /></Suspense>} />
                                        </Route>
                                        <Route path=":project/shop-drawing/change-released/:changesetId/">
                                            <Route path="start" element={<Suspense fallback={<LoadingEl />}><StartReviewReleasedChanges /></Suspense>} />
                                            <Route path="review-products" element={<Suspense fallback={<LoadingEl />}><ReviewProducts /></Suspense>} />
                                        </Route>

                                        <Route path=":project/shop-drawing/:workspace" element={<Suspense fallback={<LoadingEl />}><ShopDrawing /></Suspense>} />
                                        <Route path=":project/shop-drawing/:workspace/:subspace" element={<Suspense fallback={<LoadingEl />}><ShopDrawing /></Suspense>} />
                                        <Route path=":project/quote" element={<Suspense fallback={<LoadingEl />}><RedirectToAlternative /></Suspense>} />
                                        <Route path=":project/quote/:alternative" element={<Suspense fallback={<LoadingEl />}><Quote /></Suspense>} />
                                        <Route path=":project/quote/:alternative/:workspace" element={<Suspense fallback={<LoadingEl />}><Quote /></Suspense>} />
                                        <Route path=":project/quote/:alternative/:workspace/:subspace" element={<Suspense fallback={<LoadingEl />}><Quote /></Suspense>} />
                                        <Route path="*" element={<Suspense fallback={<LoadingEl />}><NotFoundPage /></Suspense>} />
                                    </Route>
                                    <Route path="/finance/*">
                                        <Route path="payables" element={<Suspense fallback={<LoadingEl />}><PayableList /></Suspense>} />
                                        <Route path="payables/:id" element={<Suspense fallback={<LoadingEl />}><Payable /></Suspense>} />
                                        <Route path="*" element={<NotFoundPage />} />
                                    </Route>
                                    <Route path="/logistics/*">
                                        <Route index element={<Suspense fallback={<LoadingEl />}><LogisticsDashboard /></Suspense>} />

                                        <Route path="routes/*">
                                            <Route path="to-move-popout" element={
                                                <Suspense fallback={<LoadingEl />}>
                                                    <PopoutContainer>
                                                        <ToMovePopout />
                                                    </PopoutContainer>
                                                </Suspense>
                                            } />

                                            <Route path="list" element={<Suspense fallback={<LoadingEl />}><DesktopRouteList /></Suspense>} />
                                            <Route path="weekly-planner" element={<Suspense fallback={<LoadingEl />}><WeekRoutePlanner /></Suspense>} />
                                            <Route path="*" element={<Suspense fallback={<LoadingEl />}><WeekRoutePlanner /></Suspense>} />
                                        </Route>
                                        <Route path="route/:id" element={<Suspense fallback={<LoadingEl />}><DesktopRouteDetail /></Suspense>} />
                                        <Route path="purchasing" element={<Suspense fallback={<LoadingEl />}><PurchaseOrderList /></Suspense>} />
                                        <Route path="purchasing/to-source-popout" element={
                                            <Suspense fallback={<LoadingEl />}>
                                                <PopoutContainer>
                                                    <ToSourcePopout />
                                                </PopoutContainer>
                                            </Suspense>
                                        } />
                                        <Route path="purchasing/:id" element={<Suspense fallback={<LoadingEl />}><PurchaseOrder /></Suspense>} />
                                        <Route path="warehouse" element={<Suspense fallback={<LoadingEl />}><Warehouse /></Suspense>} />

                                        <Route path="stock-count/do/:id" element={<Suspense fallback={<LoadingEl />}><StockCount /></Suspense>} />
                                        <Route path="stock-count/review/:id" element={<Suspense fallback={<LoadingEl />}><StockCountReview /></Suspense>} />
                                        <Route path="stock-count/review" element={<Suspense fallback={<LoadingEl />}><StockCounts /></Suspense>} />

                                        <Route path="stock-order/do/:id" element={<Suspense fallback={<LoadingEl />}><StockOrder /></Suspense>} />
                                        <Route path="stock-order/review/:id" element={<Suspense fallback={<LoadingEl />}><StockOrderReview /></Suspense>} />
                                        <Route path="stock-order/review" element={<Suspense fallback={<LoadingEl />}><StockOrders /></Suspense>} />

                                        <Route path="packing" element={<Suspense fallback={<LoadingEl />}><Deliveries /></Suspense>} />
                                        <Route path="packing/to-pack-popout" element={
                                            <Suspense fallback={<LoadingEl />}>
                                                <PopoutContainer>
                                                    <ToDeliverPopout />
                                                </PopoutContainer>
                                            </Suspense>
                                        } />
                                        <Route path="packing/:id" element={<Suspense fallback={<LoadingEl />}><Delivery /></Suspense>} />
                                        <Route path="driver" element={<Suspense fallback={<LoadingEl />}><RouteList /></Suspense>} />
                                        <Route path="driver/success" element={<Suspense fallback={<LoadingEl />}><RouteSuccess /></Suspense>} />
                                        <Route path="driver/:id" element={<Suspense fallback={<LoadingEl />}><RouteDetail /></Suspense>} />
                                        <Route path="driver/:id/:stopId" element={<Suspense fallback={<LoadingEl />}><RouteStop /></Suspense>} />
                                        <Route path="driver/:id/:stopId/:groupId" element={<Suspense fallback={<LoadingEl />}><RouteStopGroup /></Suspense>} />
                                        <Route path="receiving/:id" element={<Suspense fallback={<LoadingEl />}><Receive /></Suspense>} />
                                        <Route path="receiving" element={<Suspense fallback={<LoadingEl />}><Receiving /></Suspense>} />
                                        <Route path="damaged" element={<Suspense fallback={<LoadingEl />}><Damaged /></Suspense>} />
                                        <Route path="damaged/:id" element={<Suspense fallback={<LoadingEl />}><Damaged /></Suspense>} />
                                        <Route path="waste" element={<Suspense fallback={<LoadingEl />}><Waste /></Suspense>} />
                                        <Route path="waste/:id" element={<Suspense fallback={<LoadingEl />}><Waste /></Suspense>} />
                                        <Route path="*" element={<NotFoundPage />} />
                                    </Route>
                                    <Route path="/manufacturing/*">
                                        <Route path="process" element={<Suspense fallback={<LoadingEl />}><RunMfgProcess /></Suspense>} />
                                        <Route path="doors/:purchaseOrder" element={<Suspense fallback={<LoadingEl />}><ManufactureDoorPo /></Suspense>} />
                                        <Route path="doors" element={<Suspense fallback={<LoadingEl />}><ManufactureDoors /></Suspense>} />
                                        <Route path="*" element={<Redirect to="process" />} />
                                    </Route>
                                    <Route path="/settings/*">
                                        <Route path="products" element={<Suspense fallback={<LoadingEl />}><ProductSettings /></Suspense>} />

                                        <Route path="customers" element={<Suspense fallback={<LoadingEl />}><ClientList /></Suspense>} />
                                        <Route path="customer/:id" element={<Suspense fallback={<LoadingEl />}><Client /></Suspense>} />

                                        <Route path="suppliers" element={<Suspense fallback={<LoadingEl />}><Manufacturers /></Suspense>} />
                                        <Route path="supplier/:id" element={<Suspense fallback={<LoadingEl />}><Client /></Suspense>} />

                                        <Route path="*" element={<Redirect to="products" />} />
                                    </Route>
                                    <Route path="/extractor" element={<Suspense fallback={<LoadingEl />}><Extractor /></Suspense>} />
                                    <Route path="/login" element={
                                        <Suspense fallback={<LoadingEl />}>
                                            <LoginPage />
                                        </Suspense>
                                    } />
                                    <Route path="/forgot-password" element={<Suspense fallback={<LoadingEl />}><ForgotPasswordPage /></Suspense>} />
                                    <Route path="/reset-password" element={<Suspense fallback={<LoadingEl />}><ResetPasswordPage /></Suspense>} />
                                    <Route path="*" element={<Suspense fallback={<LoadingEl />}><NotFoundPage /></Suspense>} />
                                </Routes>
                                </PrintManagerProvider>
                        </Snackbar>
                    </ConfirmationProvider>
                      </WebSocketProvider>
                </AuthProvider>
              </ThemeProvider>
          </LocalizationProvider>
      </ErrorBoundary>
  );
}

export default App;
