import {SubAPI} from "./SubAPI";
import {DateString} from "./Projects";

export class EmailQueue extends SubAPI {

    get(props: {
        id: number;
    }) {
        return this.fetcher.get<OutboundEmail>("/api/email-queue/item", props)
    }
}

export type OutboundEmail = {
    id: number;
    replyTo: string;
    recipients: string;
    subject: string;
    bodyText: string;
    bodyHtml: string;
    createdAt: DateString;
    sentAt?: DateString;
    errorAt?: DateString;
    errorMessage?: string;
    attachmentDetails: EmailAttachment[]
}

export type EmailAttachment = {
    file: number;
    name: string;
}