import {PropsWithChildren} from "react";

export function WhiteColumn(props: PropsWithChildren<{
    backgroundColor?: string;
    overflow?: string;
}>) {
    return (
        <div style={{
            height: "100%",
            backgroundColor: props.backgroundColor || "white",
            display: "flex",
            flexDirection: "column",
            overflow: props.overflow,
        }}>
            {props.children}
        </div>
    )
}