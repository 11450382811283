import {useLocation, useNavigate} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import {useSyncedRef} from "../../../../misc/SyncedRef";

// globalSearchValue allows us to update the "search" portion of the URL immediately
// (e.g. when there are 2 calls to setQueryParam() within a single event handler)
let globalSearchValue = {current: ""};

export function useQueryParam<T = string>(key: string, defaultValue: T): [T, (input: T) => void] {
    const loc = useLocation();
    const locRef = useSyncedRef(loc);
    globalSearchValue.current = loc.search;

    const search = new URLSearchParams(loc.search)
    const rawValue = search.get(key) as string|null;
    const value: string = rawValue === null ? (defaultValue as string) : rawValue;

    const nav = useNavigate();

    const [state, setState] = useState<T>(value as T);
    useEffect(() => {
        setState(value as T)
    }, [value]);

    const update = useCallback((value: T|null) => {
        const search = new URLSearchParams(globalSearchValue.current)

        if(value === null || value === defaultValue) {
            search.delete(key)
        } else {
            search.set(key, value as string);
        }

        let searchStr = search.toString();
        globalSearchValue.current = searchStr;

        if(searchStr) searchStr = "?" + searchStr;

        // update immediately to prevent delay in URL update that affects things like auto-open modals
        setState(value === null ? defaultValue : value);
        nav(locRef.current.pathname + searchStr);
    }, [locRef, nav, defaultValue, key])

    return [state, update]
}