import {Column, dummySelectWrapper, lookupNestedProp, Option, SelectInput, setNestedProp, TID} from "./Table";
import React, {
    CSSProperties,
    MutableRefObject,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {EditContext} from "./EditProvider";
import {css} from "@emotion/css";
import {blue, grey} from "@mui/material/colors";
import {
    Button,
    CircularProgress, Divider, Grid,
    IconButton,
    InputAdornment, ListItemIcon, ListItemText, ListSubheader, Menu,
    MenuItem,
    Popper,
    TextField,
    Typography
} from "@mui/material";
import {Popover} from "../Popover";
import {DateString} from "../../api/Projects";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment, {Moment} from "moment";
import {useAsync2} from "nate-react-api-helpers";
import Clear from "@mui/icons-material/Clear"
import {useSyncedRef} from "../SyncedRef";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import {SelectEditor} from "./SelectEditor";
import {useSnackbar} from "../Snackbar";
import Check from "@mui/icons-material/Check";
import {TableClickBoundary} from "./TableClickBoundary";
import {Calculator} from "./Calculator";
import {autoFormat} from "../../pages/project/manage/summary/AutoFormat";

export type TransitionKey = "prev" | "next" | "up" | "down" | "left" | "right" | "blur";

export function EditCell<T extends TID>(props: {
    column: Column<T>;
    value: T;
    width: number;
    autoGrow: boolean;
    onModify(): void;
    onRender(el: HTMLElement): void;
}) {
    const [focused, setFocused] = useState(false);
    const {update, transition, onSelect, cellCustomize} = useContext(EditContext);
    const [strValue, setStrValue] = useState("");
    const defaultValue = useRef<string>("");
    const rawValueRef = useRef<any>(null);
    const columnRef = useSyncedRef(props.column);
    const editKey = useMemo(() => getEditKey(props.column, props.value), [props.column, props.value]);
    const editable = useMemo(() => getEditable(props.column, props.value), [props.column, props.value]);

    const reset = useCallback(() => {
        const col = columnRef.current;
        // @ts-ignore
        rawValueRef.current = lookupNestedProp(props.value, editKey);

        defaultValue.current = col.render(props.value, col) as string;
        strValueRef.current = defaultValue.current;
        setStrValue(defaultValue.current || "");

        setFocused(false);
        console.log('reset', props.value, editKey, defaultValue.current)
    }, [props.value, columnRef, editKey]);

    useEffect(() => {
        if(editable?.type === "button") return; // don't auto-reset for button
        reset();

        // only reset when the column name changes, could just be a root-level re-render (e.g. from auth update)
    }, [reset, props.column.name, columnRef, editable])

    const strValueRef = useRef(strValue);
    strValueRef.current = strValue;

    const modifyRef = useRef(props.onModify)

    const snack = useSnackbar();
    const snackError = snack.error;

    const submit = useCallback(async (tkey?: TransitionKey) => {
        if(strValueRef.current !== defaultValue.current) {

            let value: any = strValueRef.current;
            if(editable?.parseValue) {
                value = editable.parseValue(rawValueRef.current, strValueRef.current)
            } else {
                if (editable?.type === "number") {
                    value = Calculator.eval(strValueRef.current);
                }

                if (editable?.type === "date") {
                    value = rawValueRef.current;
                }

                if (editable?.type === "lookup") {
                    value = rawValueRef.current;
                }
            }

            if(editable?.validate) {
                try {
                    editable.validate(value);
                } catch (e: any) {
                    // @ts-ignore
                    snackError(e.message);
                    return;
                }
            }

            if(editable?.type === "string" && editable.autoFormat) {
                value = autoFormat(value, editable.autoFormat)
            }

            const updateObj: any = {
                updatedAt: "2000-01-01T01:11:22.264147Z", // special format for backend, backend will overwrite this value
            }

            let val = Object.assign({}, props.value, updateObj)

            if(editable?.onChangeBeforeSave) {
                editable.onChangeBeforeSave(val, strValueRef.current)
            }

            if(columnRef.current.editObj) {
                try {
                    columnRef.current.editObj(val, props.value, value);
                } catch (e: any) {
                    snackError(e.message);
                    return;
                }
            } else {
                if (!editKey) throw new Error("missing editKey")
                setNestedProp(val as any, editKey, value)
            }

            if(editable?.type === "lookup") {
                setNestedProp(val, editable.displayKey, strValueRef.current);
            }

            modifyRef.current()
            update(val);
        }

        if(tkey) {
            transition(tkey)
        }
        
        setFocused(false);
    }, [update, props.value, columnRef, editable, transition, editKey, snackError]);

    useEffect(() => {
        if(focused && onSelect.lastValue) {
            onSelect.emit(Object.assign(onSelect.lastValue, {
                editing: focused,
            }))
        }
    }, [focused, onSelect])

    useEffect(() => {
        if(focused) return;
        if(editable?.type === "button") return;

        const keydown = (e: KeyboardEvent) => {
            if(e.ctrlKey || e.metaKey) return; // allow for ctrl+r or cmd+r

            e.stopPropagation();
            e.preventDefault();

            switch(e.key) {
                case "Backspace":
                case "Delete":
                    setStrValue("");
                    strValueRef.current = ""
                    submit();
                    break;
                case "Tab":
                    transition(e.shiftKey ? "prev" : "next");
                    break;
                case "ArrowRight":
                    transition("right");
                    break;
                case "ArrowLeft":
                    transition("left");
                    break;
                case "ArrowUp":
                    transition("up");
                    break;
                case "ArrowDown":
                    transition("down");
                    break;
                case "Enter":
                    if(focused) {
                        transition("down");
                    } else {
                        setFocused(true);
                    }
                    break;
                case "Escape":
                    onSelect.emit(null);
                    break;
                default:
                    if(e.metaKey) return;
                    if(e.ctrlKey) return;

                    if(e.key.length === 1) {
                        setStrValue(e.key);
                        setFocused(true);
                    }

                    break;
            }
        };

        document.body.addEventListener("keydown", keydown);

        return () => {
            document.body.removeEventListener("keydown", keydown);
        }
    }, [focused, onSelect, transition, submit, columnRef, editable]);

    const [anchor, setAnchor] = useState<HTMLDivElement|null>(null);
    const anchorRef = useRef(anchor);
    anchorRef.current = anchor;

    const OnFocus = props.column.showHintOnFocus;

    useEffect(() => {
        if(anchor) {
            props.onRender(anchor)
        }
    })

    const OnFocus2 = cellCustomize?.onFocus?.(props.value, props.column, anchor) || null;

    useEffect(() => {
        if(focused) return;
        if(editable?.type !== "button") return;
        if(!anchor) return;

        setFocused(true)
    }, [anchor, props.column, focused, editable]);

    return (
        <div className={editCell}
             ref={setAnchor}
             style={{
                minWidth: props.width,
                width: props.autoGrow ? undefined : props.width,
                textAlign: props.column.alignRight ? "right" : undefined,
                flex: props.autoGrow ? 1 : undefined,
            }}
            onClick={e => {
                if(editable) {
                    e.stopPropagation()
                    e.preventDefault()
                }

                if(editable?.type === "checkbox") return;
                setFocused(true)
            }}>
            <span>{props.column.render(props.value, props.column) || <>&nbsp;</>}</span>
            {editable?.type === "select" && <SelectHint />}
            {focused &&
                <Inner anchor={anchor}
                       onReset={reset}
                       onDeselect={() => {
                           console.log('deselect')
                           setFocused(false);
                           onSelect.emit(null)
                       }}
                       onBlur={() => setFocused(false)}
                       onSubmit={submit}
                       strValue={strValue}
                       onStrChange={setStrValue}
                       strValueRef={strValueRef}
                       rawValueRef={rawValueRef}
                       width={props.width}
                       column={props.column}
                       value={props.value}
            />}
            {OnFocus && <OnFocus anchorEl={anchor} row={props.value} />}
            {OnFocus2}
        </div>
    );
}

function SelectHint() {
    return (
        <div style={{
            position: "absolute", left: "100%",
            border: "1px solid " + grey["300"],
            background: grey["200"],
            opacity: "0.9",
            top: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            zIndex: 10,
        }}>
            <ArrowDropDown />
        </div>
    )
}

export function getEditable<T extends TID>(col: Column<T>, row: T) {
    if(col.editable) return col.editable;
    if(col.editableFunc) {
        const obj = col.editableFunc(row)
        if(!obj) return null;

        return obj.editable;
    }

    return null;
}

export function getEditKey<T extends TID>(col: Column<T>, row: T) {
    if(col.editKey) return col.editKey;
    if(col.editableFunc) {
        const obj = col.editableFunc(row)
        if(!obj) return null;

        return obj.editKey
    }

    return null;
}

function Inner<T extends TID>(props: {
    column: Column<T>,
    value: T,
    width: number,

    strValue: string;
    onStrChange(value: string): void;
    strValueRef: MutableRefObject<string>;
    rawValueRef: MutableRefObject<any>;

    onReset(): void;
    onSubmit(tkey?: TransitionKey): Promise<void>
    onDeselect(): void;
    onBlur(): void;
    anchor: any;
}) {
    const {update, setHasPopout} = useContext(EditContext);
    const {strValue, onStrChange, strValueRef, rawValueRef} = props;
    const editable = useMemo(() => getEditable(props.column, props.value), [props.column, props.value]);
    const editableType = editable?.type;
    const editKey = useMemo(() => getEditKey(props.column, props.value), [props.column, props.value]);

    useEffect(() => {
        switch(editableType) {
            case "button":
            case "custom":
            case "date":
            case "select":
            case "lookup":
                setHasPopout(true);
                break;
            default:
                if(editableType === "long-string") {
                    setHasPopout(true);
                } else {
                    setHasPopout(false);
                }
                break;
        }

        return () => setHasPopout(false);
    }, [editableType, setHasPopout]);

    const columnRef = useSyncedRef(props.column);
    const EditableRender = useMemo(() => {
        if(editable?.type === "custom" || editable?.type === "button") return editable.render;
        return null;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnRef, props.column.name, editable]);

    if(EditableRender) {
        return (
            <EditableRender
                initialValue={strValue}
                anchor={props.anchor}
                row={props.value}
                width={props.width}
                onCancel={props.onDeselect}
                onDone={async (value: T) => {
                    await update(value)
                    props.onDeselect();
                }} />
        );
    }

    if(editable?.type === "date") {
        if(!editKey) return null;
        const val = lookupNestedProp(props.value, editKey) as DateString | null;
        return (
            <div style={{width: "100%"}}>
                {props.column.nullable && <Button style={{paddingTop: 0, paddingBottom: 0}} color="secondary" size="small" onClick={() => {
                    rawValueRef.current = null;
                    strValueRef.current = "";
                    onStrChange(strValueRef.current);
                    props.onSubmit("next");
                }}>Clear</Button>}
                <DatePicker
                    open={true}
                    value={val ? moment.utc(val) : null}
                    onClose={() => props.onSubmit()}
                    PopperProps={{
                        anchorEl: props.anchor,
                    }}
                    onChange={value => {
                        if(!value) {
                            props.onSubmit("next");
                            return;
                        }

                        //@ts-ignore
                        const str = (value as Moment).utc().toJSON()
                        rawValueRef.current = str;

                        if(!editKey) return;
                        setNestedProp(props.value, editKey, str);

                        // @ts-ignore
                        strValueRef.current = props.column.render(props.value);
                        onStrChange(strValueRef.current);

                        props.onSubmit("next");
                    }}
                    renderInput={props => {
                        return <div ref={props.ref}></div>
                    }} />
            </div>
        )
    }

    if(editable?.type === "select") {
        if(!editKey) return null;

        const val = lookupNestedProp(props.value, editKey)

        return (<SelectEditor
            anchor={props.anchor}
            freeSolo={editable.freeSolo}
            options={editable.options(dummySelectWrapper(props.value))}
            value={val}
            initialValue={strValue}
            onBlur={props.onDeselect}
            onSelect={value => {
                strValueRef.current = value;
                onStrChange(value);
                props.onSubmit("blur");
            }} />)
    }

    if(editable?.type === "lookup") {
        let val: any[];

        if(editable.getSelected) {
            val = editable.getSelected(props.value)
            if(!(val instanceof Array)) {
                val = [val];
            }
        } else {
            if (!props.column.editKey) return null;
            val = [lookupNestedProp(props.value, props.column.editKey)];
        }

        return (
            <Lookup width={props.width}
                    column={props.column}
                    lookup={editable.options}
                    value={val}
                    object={props.value}
                    multiple={editable.multiple}
                    onChange={o => {
                        rawValueRef.current = o.value;
                        strValueRef.current = o.display;
                        onStrChange(o.display);
                        props.onSubmit("blur");
                    }} />
        )
    }

    if(editable?.type === "long-string") {
        return (
            <TableCellTextPopper
                anchor={props.anchor}
                alignRight={props.column.alignRight || false}
                oversize={true}
                onArrow={() => {}}
                onTab={() => {}}
                onEnter={(e) => {
                    // do nothing
                }}
                onEsc={(e) => {
                    props.onReset();
                }}
                onBlur={() => {
                    // props.onSubmit();
                }}
                onOversizeDone={() => props.onSubmit()}
                onOversizeCancel={() => props.onReset()}
                value={strValue}
                onChange={onStrChange}
            />
        );
    }

    return (
        <TableCellTextPopper
                anchor={props.anchor}
                alignRight={props.column.alignRight || false}
                onEnter={(e) => {
                    props.onSubmit(e.shiftKey ? "up" : "down");
                }}
                onEsc={(e) => {
                    props.onReset();
                }}
                onTab={(e) => {
                    props.onSubmit(e.shiftKey ? "prev" : "next");
                }}
                onArrow={dir => {
                    props.onSubmit(dir);

                }}
                onBlur={() => {
                    props.onSubmit();
                }}
                value={strValue}
                onChange={onStrChange}
            />
    );
}

export function TableCellTextPopper(props: {
    anchor: any;
    alignRight?: boolean
    onEnter(e: any): void;
    onEsc(e: any): void;
    onTab(e: any): void;
    onArrow(e: TransitionKey): void;
    onBlur?(): void;
    onFocus?(): void;
    value: string;
    onChange(value: string): void;
    oversize?: boolean;
    onOversizeDone?(): void;
    onOversizeCancel?(): void;
}) {
    const {anchor, alignRight, oversize} = props;
    const hasComplete = useRef(false);
    const completeGuard = useCallback(() => {
        if(hasComplete.current) return false;
        hasComplete.current = true;
        return true;
    }, [])

    const sizeStyle = useRef<CSSProperties>({});
    const anchorRef = useSyncedRef(anchor);
    const textRef = useRef<HTMLTextAreaElement|null>(null);

    const autoSetTextSize = useCallback((ref: HTMLElement | null) => {
        const anc = anchorRef.current;
        if(!anc) return;
        if(!ref) return;

        ref = ref.parentElement;
        if(!ref) return;

        let height = anc.clientHeight + 6;
        let width = anc.clientWidth + 4;

        if(oversize) {
            height = Math.max(height, 200)
            width = Math.min(Math.max(width, 600), window.innerWidth - 300);
        }

        ref.style.height = height + 'px';
        ref.style.width = width + 'px';
        sizeStyle.current = {
            height: height,
            width: width,
        };
    }, [anchorRef, oversize]);

    const hasReselected = useRef(false);

    const mods = useMemo(() => [
        {
            name: "offset",
            options: {
                // @ts-ignore
                offset: ({ reference, placement, popper }) => [
                    0,
                    ["top", "bottom"].includes(placement)
                        ? -reference.height - (popper.height - reference.height) / 2
                        : -reference.width - (popper.width - reference.width) / 2
                ]
            }
        }], []);

    const {onSelect} = useContext(EditContext);

    const inner = (
        <TableClickBoundary style={oversize ? {
            padding: 2,
            backgroundColor: "white",
            boxShadow: "rgb(0 0 0 / 49%) 1px 1px 6px 0px"
        } : undefined}
             enabled={oversize}
        >
            <div key="wrapper" className={text} style={sizeStyle.current}>
                    <textarea
                        key="text-field"
                        style={{textAlign: alignRight ? "right" : undefined, width: "auto"}}
                        ref={r => {
                            textRef.current = r;

                            if(textRef.current && !hasReselected.current) {
                                hasReselected.current = true;
                                const end = props.value.length;
                                textRef.current.setSelectionRange(end, end)
                                textRef.current.focus();
                                autoSetTextSize(textRef.current);
                            }
                        }}
                        value={props.value}
                        onKeyDown={e => {
                            if(oversize) return;

                            e.stopPropagation();

                            switch(e.key) {
                                case "Enter":
                                    e.preventDefault();

                                    if(completeGuard()) {
                                        props.onEnter(e);
                                    }

                                    e.currentTarget.blur();
                                    break;
                                case "Escape":
                                    e.preventDefault();
                                    if(completeGuard()) {
                                        props.onEsc(e);
                                    }
                                    e.currentTarget.blur();
                                    break;
                                case "Tab":
                                    e.preventDefault();
                                    if(completeGuard()) {
                                        props.onTab(e);
                                    }
                                    e.currentTarget.blur();
                                    break;
                                case "ArrowUp":
                                    if(isSectionAt(e.currentTarget, "start")) {
                                        if(completeGuard()) {
                                            props.onArrow("up");
                                            e.currentTarget.blur();
                                        }
                                    }

                                    break;
                                case "ArrowDown":
                                    if(isSectionAt(e.currentTarget, "end")) {
                                        if(completeGuard()) {
                                            props.onArrow("down");
                                            e.currentTarget.blur();
                                        }
                                    }

                                    break;
                                case "ArrowLeft":
                                    if(isSectionAt(e.currentTarget, "start")) {
                                        if(completeGuard()) {
                                            props.onArrow("left");
                                            e.currentTarget.blur();
                                        }
                                    }

                                    break;
                                case "ArrowRight":
                                    if(isSectionAt(e.currentTarget, "end")) {
                                        if(completeGuard()) {
                                            props.onArrow("right");
                                            e.currentTarget.blur();
                                        }
                                    }

                                    break;
                            }

                            autoSetTextSize(e.currentTarget);
                        }}
                        onFocus={() => {
                            if(!props.onFocus) return;
                            if(completeGuard()) props.onFocus();
                        }}
                        onBlur={() => {
                            if(!props.onBlur) return;
                            if(completeGuard()) props.onBlur()
                        }}
                        onChange={e => props.onChange(e.target.value)}
                        rows={1}
                    />
            </div>
            {oversize && <div style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: 4,
                background: "white",
            }}>
                <Button onClick={() => {
                    if(props.onOversizeCancel) props.onOversizeCancel()
                    onSelect.emit(null)
                }} size="small">Cancel</Button>
                <Button onClick={props.onOversizeDone} variant="contained" size="small">Update</Button>
            </div>}
        </TableClickBoundary>
    )

    if(props.oversize) {
        return (
            <Menu key="menu" open anchorEl={anchor} anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}>
                {inner}
            </Menu>
        )
    }

    return (
        <Popper key="popper" anchorEl={anchor} placement="top" modifiers={mods} open>
            {inner}
        </Popper>
    )
}

function isSectionAt(element: HTMLTextAreaElement, position: "start" | "end") {
    if(element.selectionDirection === "forward") {
        if(position === "start") return element.selectionStart === 0;
        return element.selectionEnd === element.value.length;
    } else if(element.selectionDirection === "backward") {
        if(position === "start") return element.selectionEnd === 0;
        return element.selectionStart === element.value.length;
    }

    if(position === "start") {
        return element.selectionStart === 0 || element.selectionEnd === 0;
    }

    return element.selectionStart === element.value.length || element.selectionEnd === element.value.length;
}

function Lookup<T extends TID>(props: {
    width: number;
    column: Column<T>
    lookup: (input: string, value: SelectInput<T>) => Promise<Option[]>
    value: any[];
    object: T;
    multiple?(list: Option[]): Option;
    onChange(value: Option): void;
}) {
    const [anchor, setAnchor] = useState<HTMLDivElement|null>(null);
    const [search, setSearch] = useState("");
    const {onSelect} = useContext(EditContext);

    const isMulti = !!props.multiple;
    const [listValue, setListValue] = useState<Option[]>([]);

    const lookupRef = useRef(props.lookup);
    lookupRef.current = props.lookup;

    const list = useAsync2((input: {search: string, object: T}) =>
        lookupRef.current(input.search, dummySelectWrapper(input.object)),
        {search: search, object: props.object},
        [search, props.lookup, props.object]);

    const result = list.result;
    const listValueRef = useSyncedRef(listValue);
    const [hasInit, setHasInit] = useState(false);

    useEffect(() => {
        if(hasInit) return;
        if(!result) return;
        setListValue(result.filter(f => props.value.indexOf(f.value) !== -1));
        setHasInit(true);
    }, [props.value, result, hasInit, listValueRef])

    return (
        <div className={editCell} ref={setAnchor} style={{width: props.width}}>
            {anchor && <Popover
                anchorEl={anchor}
                open
                style={{fontSize: "0.5rem"}}
                onClose={() => {
                    onSelect.emit(null);
                }}
            >
                <TextField fullWidth size="small" value={search}
                           onChange={e => setSearch(e.currentTarget.value)}
                           InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton onClick={() => setSearch("")}>
                            <Clear />
                        </IconButton>
                    </InputAdornment>
                }} />
                {list.loading && <CircularProgress />}
                {list.error && <Typography color="red">{list.error}</Typography>}
                {list.result?.map((o: Option) => {
                    if(o.subHeading) {
                        return <ListSubheader>{o.display}</ListSubheader>
                    }

                    return (<MenuItem disabled={o.disabled} onClick={() => {
                        if(!isMulti) {
                            props.onChange(o);
                        } else {
                            if(listValue.filter(l => l.value === o.value).length === 0) {
                                setListValue(old => [...old, o]);
                            } else {
                                setListValue(old => old.filter(v => v.value !== o.value));
                            }

                        }
                    }} selected={(listValue.filter(l => l.value === o.value).length > 0)}
                              key={o.value} value={o.value}>
                        <ListItemIcon>
                            {(listValue.filter(l => l.value === o.value).length > 0) && <Check />}
                        </ListItemIcon>
                        <ListItemText>
                            {o.display}
                        </ListItemText>
                    </MenuItem>)
                })}
                {isMulti &&
                    <div>
                        <Divider />
                        <div style={{padding: 8}}>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Button
                                        variant="contained" size="small"
                                        onClick={() => {
                                            if(props.multiple) {
                                                props.onChange(props.multiple(listValue))
                                            } else {
                                                props.onChange(listValue[0])
                                            }
                                        }}
                                    >Done</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>}
            </Popover>}
        </div>
    )
}

const editCell = css({
    position: "relative",
    cursor: "pointer",
    lineHeight: "1em",
    display: "flex",
    flexDirection: "column" as any,
    height: "100%",
    fontSize: "0.8rem",
    border: "2px solid " + blue["600"],
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
})

const text = css({

    border: "2px solid " + blue["600"],
    display: "flex",
    flexDirection: "column",
    flex: 1,


    "& textarea": {
        flex: 1,
        fontFamily: "inherit",
        resize: "none",
        paddingLeft: 4,
        paddingRight: 4,
        fontSize: "0.8rem",
        border: "none",
        width: 250,
        height: 30,

        "&:focus": {
            outline: "none",
        }
    }
} as any)